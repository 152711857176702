<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h5"> Extraction du contrat </v-card-title>
        <v-card-text>
          <v-alert v-if="error == true && loading == false" type="error">
            {{ messageDialoge }}
          </v-alert>
          <v-alert v-if="error == false && loading == false" type="success">
            {{ messageDialoge }}
          </v-alert>
          <v-flex class="text-center">
            <v-progress-circular v-if="loading == true" :size="150" :width="7" color="primary" indeterminate>
              Patientez ...
            </v-progress-circular>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="loading == false" color="green darken-1" text @click="dialog = false">
            FERMER
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addData" max-width="850" persistent>
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-card-title class="text-h5"> Plus des données </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-row class="justify-left">
              <v-col cols="10" md="5" sm="5" lg="5" xl="5">
                <span>Situation du point</span>
                <v-radio-group v-model="situationDuPoint" row>
                  <v-radio label="Changement de fournisseur" value="changement"></v-radio>
                  <v-radio label="Mise en service" value="mise"></v-radio>
                  <v-radio label="Renouvellement" value="renouvellement"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="10" md="5" sm="5" lg="5" xl="5">
                <span>Type de raccordement</span>
                <v-radio-group v-model="typeDeReccordement" :disabled="situationDuPoint === 'changement'" row>
                  <v-radio label="Standard" value="standard"></v-radio>
                  <v-radio label="Express" value="expresse"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="10" md="5" sm="5" lg="5" xl="5">
                <span>Ajouter une adresse de facturation</span>
                <v-radio-group v-model="addAdrsFact" row>
                  <v-radio label="oui" :value="true"></v-radio>
                  <v-radio label="non" :value="false"></v-radio>
                </v-radio-group>
              </v-col>

              <v-col cols="10" md="5" sm="5" lg="5" xl="5">
                <v-text-field v-if="addAdrsFact == true" outlined :rules="[rules.required]" v-model="adresseFacturation"
                  label="Adresse de facturation"></v-text-field>
              </v-col>

              <v-col cols="10" md="5" sm="5" lg="5" xl="5">
                <v-text-field v-if="addAdrsFact == true" outlined
                  :rules="[rules.required, rules.phoneisNumber, rules.phoneLength]" v-model="numTeleFacturation"
                  label="N° Tele"></v-text-field>
              </v-col>

              <v-col cols="10" md="5" sm="5" lg="5" xl="5">
                <v-text-field v-if="addAdrsFact == true" outlined :rules="rules.emailRules" v-model="emailFacturation"
                  label="Email"></v-text-field>
              </v-col>
              <v-col cols="10" md="10" sm="10" lg="10" xl="10">
                <v-btn v-if="loading == false" :color="$colors[0]" class="white--text" @click="createContrat">
                  Confirmer
                </v-btn>
                <v-btn v-if="loading == false" color="error" text @click="addData = false">
                  Annuler
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <div>
      <v-btn @click="addData = true" icon depressed class="ma-auto text-capitalize">
        <v-icon :color="$colors[0]">mdi-text-box-edit</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import { degrees, PDFDocument, rgb, StandardFonts, layoutMultilineText } from "pdf-lib";
import download from "downloadjs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import axios from "axios";
export default {
  name: "vattenfallContrat",
  props: {
    donner: null,
    numCompteur: null,
    societe: null,
    donnerEnedis: null,
  },
  data: () => ({
    addData: false,
    addAdrsFact: false,
    adresseFacturation: "",
    numTeleFacturation: "",
    emailFacturation: "",
    nbrMonth: "12",
    situationDuPoint: "changement",
    typeDeReccordement: "standard",
    rules: {
      required: (value) => !!value || "Ce champ est obligatoire.",
      siretLength: (v) => v.length == 14 || "Le siret doit comporter 14 chiffres",
      isNumber: (v) =>
        v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
      compteurElecLength: (v) =>
        v.length == 14 || "Le RAE / PDL est une suite de 14 chiffres",
      isGazCompteur: (v) =>
        v.match(/^(GI)[0-9]{6}$/) ||
        (v.length == 14 && v.match(/^[0-9]+$/) != null) ||
        "Le numéro PCE/ PDL doit commencer par (GI) ou comporter 14 chiffres",
      phoneLength: (v) =>
        v.length == 10 || "Le numéro de téléphone est une suite de 10 chiffres",
      phoneisNumber: (v) =>
        v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
      margeMaxMin: (v) =>
        (v <= 25 && v >= 2) || "La marge doit être comprise entre 2 et 25",

      emailRules: [
        (v) => !!v || "E-mail obligatoire",
        (v) => /.+@.+\.+.+/.test(v) || "l'adresse mail est invalide",
      ],
    },
    dialog: false,
    error: false,
    loading: false,
    messageDialoge: "",
    energieVert: true,
    percent: "25",
    generatedPdf: null,
    type: "",
    positionPage1: 1,
    positionPage1: 10,
    positionPage1: 10,
    positionPage1: 10,
    positionPage1: 10,
    positionPage1: 10,

    data: {
      dateAjourdhui:
        new Date().getDate() +
        "/" +
        new Date().getMonth() +
        1 +
        "/" +
        new Date().getFullYear(),
      dateDebut: "10/10/2020",
      dateFin: "10/10/2022",
      pdl: "14552022144555",
      siret: "11111111111111",
      codeNaf: "1212Z",
      adresseSociete: "199 RUE, 12554 commune d'atte",
      adresseCompteur: "199 RUE, 12554 commune d'atte",
      codePostaleCompteur: "",
      voieCompteur: "",
      communeCompteur: "",
      codePostaleSociete: "",
      voieSociete: "",
      communeSociete: "",

      codePostale: "",
      nom: "chrif",
      prenom: "slimen",
      email: "slimenc6@gmail",
      numTele: "0755448899",
      consommationAnuuelle: "377.20",
      typeCompteur: "BTINFMUDT",
      kva: "24",
      raison: "Gaz elec moins cher Gaz elec moins cher Gaz elec moins cher",
      nbrMonth: "2",
      cabase: "",
      cahp: "",
      cahc: "",
    },
  }),

  created() {
    //this.verifyApplicationDataSecurity()
  },
  methods: {
    async createContrat() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.addData = false;
        this.dialog = true;
        this.error = false;
        this.loading = true;
        this.messageDialoge = "";

        var autreData = {
          addAdrsFact: this.addAdrsFact,
          adresseFacturation: this.adresseFacturation,
          numTeleFacturation: this.numTeleFacturation,
          emailFacturation: this.emailFacturation,
          situationDuPoint: this.situationDuPoint,
          typeDeReccordement: this.typeDeReccordement,
        };
        var dataToSend = {
          donner: this.donner,
          donnerEnedis: this.donnerEnedis,
          optionTarifaire: this.optionTarifaire,
          numCompteur: this.numCompteur,
          societe: this.societe,
          autreData: autreData,
        };
        const response = await axios({
          url: process.env.VUE_APP_URL_EDITION_CONTRACT_V2 + 'contracts/vattenfall',
          method: 'POST',
          data: dataToSend,
          responseType: 'blob', // Important: set responseType to blob
        });

        // Create blob from response data directly
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        });

        // Create download link
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = dataToSend.societe.Raison + "_" + dataToSend.numCompteur + "_" + new Date().toISOString().split('T')[0] + '.docx';

        // Trigger download
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
        this.dialog = false;
        this.error = false;
        this.loading = false;
        this.messageDialoge = "";
      }
    },
    async getCodePostale(adresse) {
      var myRe = new RegExp("\\d\\d\\d\\d\\d", "");
      var myArray = myRe.exec(adresse);
      var voie = "";
      var codePostale = "";
      var commune = "";
      if (myArray != null) {
        codePostale = myArray[0];
        if (adresse.split(codePostale).length >= 2) {
          voie = adresse.split(codePostale)[0];
          commune = adresse.split(codePostale)[1];
        } else {
          commune = "";
          voie = adresse;
        }
      } else {
        voie = adresse;
      }
      return [codePostale, voie, commune];
    },

    async addPagesToPdf(mergedPdf, donorPdf) {
      const copiedPages = await mergedPdf.copyPages(donorPdf, donorPdf.getPageIndices());
      copiedPages.forEach((page) => {
        mergedPdf.addPage(page);
      });
      return mergedPdf;
      // whatever
    },
    async extraireDonne(
      donner,
      donnerEnedis,
      optionTarifaire,
      numCompteur,
      societe,
      autreData
    ) {
      var data = {};
      data.dateAjourdhui =
        new Date().getDate() +
        "/" +
        (new Date().getMonth() + 1) +
        "/" +
        new Date().getFullYear();
      if (donnerEnedis.donneTechniqueInfo[0].result == "valide") {
        data.adresseCompteur =
          donnerEnedis.donneTechniqueInfo[0].data.numVoie +
          " " +
          donnerEnedis.donneTechniqueInfo[0].data.codePostal +
          " " +
          donnerEnedis.donneTechniqueInfo[0].data.commune;
        data.codePostaleCompteur = donnerEnedis.donneTechniqueInfo[0].data.codePostal;
        data.voieCompteur = donnerEnedis.donneTechniqueInfo[0].data.numVoie;
        data.communeCompteur = donnerEnedis.donneTechniqueInfo[0].data.commune;
      } else {
        data.adresseCompteur = "";
        data.codePostaleCompteur = "";
        data.voieCompteur = "";
        data.communeCompteur = "";
      }
      data.dateDebut = donner.dateDebut + "";
      data.dateFin = donner.dateFin + "";
      data.pdl = numCompteur + "";
      data.siret = societe.siret + "";
      data.adresseSociete = societe.AdressePostal + "";
      data.nom = societe.nom + "";
      data.prenom = societe.prenom + "";
      data.email = societe.AdresseEmail + "";
      data.numTele = societe.numTele + "";
      data.fonction = societe.fonction + "";
      data.consommationAnuuelle = donner.consomationGlobale + "";
      data.kva = donnerEnedis.donneTechniqueInfo[0].data.kva + "";
      data.raison = societe.Raison + "";
      data.nbrMonth = donner.nbrMonth + "";
      data.typeCompteur = donnerEnedis.donneTechniqueInfo[0].data.typeCompteur;
      data.codeNaf = societe.codeNaf;
      data.codeInsee = societe.codeInsee;
      data.codePostaleSociete = societe.codePostal;
      data.voieSociete = societe.Voie;
      data.communeSociete = societe.commune;
      data.parametreCompteur = donner.parametreCompteur;
      data.nbrMonth = autreData.nbrMonth;
      data.situationDuPoint = autreData.situationDuPoint;
      data.typeDeReccordement = autreData.typeDeReccordement;
      data.addAdrsFact = autreData.addAdrsFact;
      data.adresseFacturation = autreData.adresseFacturation;
      data.numTeleFacturation = autreData.numTeleFacturation;
      data.emailFacturation = autreData.emailFacturation;
      return data;
    },
    async createPdf() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.addData = false;
        this.dialog = true;
        this.error = false;
        this.loading = true;
        this.messageDialoge = "";
        var autreData = {
          addAdrsFact: this.addAdrsFact,
          adresseFacturation: this.adresseFacturation,
          numTeleFacturation: this.numTeleFacturation,
          emailFacturation: this.emailFacturation,
          nbrMonth: this.nbrMonth,
          situationDuPoint: this.situationDuPoint,
          typeDeReccordement: this.typeDeReccordement,
        };
        var dataRecieved = {
          donner: this.donner,
          donnerEnedis: this.donnerEnedis,
          optionTarifaire: this.optionTarifaire,
          numCompteur: this.numCompteur,
          societe: this.societe,
          autreData: autreData,
        };
        var societe = dataRecieved.societe;
        var donner = dataRecieved.donner;
        var donnerEnedis = dataRecieved.donnerEnedis;
        var optionTarifaire = dataRecieved.optionTarifaire;
        var numCompteur = dataRecieved.numCompteur;
        var autreData = dataRecieved.autreData;
        var data = await this.extraireDonne(
          donner,
          donnerEnedis,
          optionTarifaire,
          numCompteur,
          societe,
          autreData
        ).then((res) => {
          return res;
        });
        var pdfBase = null;
        pdfBase = await this.generatePdf(data);

        download(
          pdfBase,
          numCompteur + "_Vattenfall_" + societe.Raison + "_.pdf",
          "application/pdf"
        );

        this.dialog = false;
        this.error = false;
        this.loading = false;
        this.messageDialoge = "";
      }
    },
    async generatePdf(data) {
      /////////////// page 1 ///////////////////////////////////////
      var url0 = `/pdf/vattenfall/contartVattenfallMerged-1.pdf`;
      const pageZero = await fetch(url0).then((res) => res.arrayBuffer());
      // Load a PDFDocument from each of the existing PDFs
      const pageZeroDoc = await PDFDocument.load(pageZero);

      const form = pageZeroDoc.getForm();
      const raison = form.getTextField("raison");
      raison.setText(data.raison);
      raison.setFontSize(7);
      const siren = form.getTextField("siren");
      siren.setText(data.siret.substring(0, 9));
      siren.setFontSize(7);
      var adresseSocieteList = await this.getCodePostale(data.adresseSociete);
      var adresseCompteurList = await this.getCodePostale(data.adresseCompteur);
      var adresseFacturationList = await this.getCodePostale(data.adresseFacturation);

      const adresseSociete = form.getTextField("adresseSociete");
      adresseSociete.setText(
        data.adresseSociete + "\n" + adresseSocieteList[0] + " " + adresseSocieteList[2]
      );
      adresseSociete.setFontSize(6.5);
      const adresseCompteur = form.getTextField("adresseCompteur");
      adresseCompteur.setText(
        data.adresseCompteur +
        "\n" +
        adresseCompteurList[0] +
        " " +
        adresseCompteurList[2]
      );
      adresseCompteur.setFontSize(6.5);
      const siret = form.getTextField("siret");
      siret.setText(data.siret);
      siret.setFontSize(7);
      const codeNaf = form.getTextField("codeNaf");
      codeNaf.setText(data.codeNaf);
      codeNaf.setFontSize(7);
      const codeInsee = form.getTextField("codeInsee");
      codeInsee.setText(data.codeInsee);
      codeInsee.setFontSize(7);
      const numCompteur = form.getTextField("numCompteur");
      numCompteur.setText(data.pdl);
      numCompteur.setFontSize(7);
      const nomClient = form.getTextField("nomClient");
      nomClient.setText(data.nom + " " + data.prenom);
      nomClient.setFontSize(7);
      const fonction = form.getTextField("fonction");
      if (data.fonction == "") {
        fonction.setText("gerant");
      } else {
        fonction.setText(data.fonction);
      }
      fonction.setFontSize(7);
      const numTele = form.getTextField("numTele");
      numTele.setText(data.numTele);
      numTele.setFontSize(7);
      const email = form.getTextField("email");
      email.setText(data.email);
      email.setFontSize(7);
      const kva = form.getTextField("kva");
      kva.setText(data.kva);
      kva.setFontSize(7);
      const typeEnergie = form.getRadioGroup("typeEnergie");
      if (data.parametreCompteur == "C5BASE") {
        typeEnergie.select("Base");
      } else {
        typeEnergie.select("hphc");
      }

      const dateEffet = form.getTextField("dateEffet");
      dateEffet.setText(data.dateDebut);
      dateEffet.setFontSize(7);

      const adresseFacturation = form.getTextField("adresseFacturation");
      const numTeleFact = form.getTextField("numTeleFact");
      const emailFact = form.getTextField("emailFact");
      if (data.addAdrsFact == true) {
        adresseFacturation.setText(
          data.adresseFacturation +
          "\n" +
          adresseFacturationList[0] +
          " " +
          adresseFacturationList[2]
        );
        adresseFacturation.setFontSize(7);

        numTeleFact.setText(data.numTeleFacturation);
        numTeleFact.setFontSize(7);

        emailFact.setText(data.emailFacturation);
        emailFact.setFontSize(7);
      }

      const nbrMonth = form.getRadioGroup("nbrMonth");
      nbrMonth.select(data.nbrMonth);
      const confirm = form.getRadioGroup("confirm");
      confirm.select("confirm");
      const confirm1 = form.getRadioGroup("confirm1");
      confirm1.select("confirm1");
      const situationPoint = form.getRadioGroup("situationPoint");
      situationPoint.select(data.situationDuPoint);
      //nbrMonth.select('mise')
      const typeRecord = form.getRadioGroup("typeRecord");
      typeRecord.select(data.typeDeReccordement);

      raison.enableReadOnly();
      siren.enableReadOnly();
      adresseSociete.enableReadOnly();
      adresseCompteur.enableReadOnly();
      siret.enableReadOnly();
      codeNaf.enableReadOnly();
      codeInsee.enableReadOnly();
      numCompteur.enableReadOnly();
      nomClient.enableReadOnly();
      fonction.enableReadOnly();
      numTele.enableReadOnly();
      email.enableReadOnly();
      kva.enableReadOnly();
      adresseFacturation.enableReadOnly();
      numTeleFact.enableReadOnly();
      emailFact.enableReadOnly();
      typeEnergie.enableReadOnly();
      confirm.enableReadOnly();
      confirm1.enableReadOnly();
      situationPoint.enableReadOnly();
      typeRecord.enableReadOnly();
      nbrMonth.enableReadOnly();
      //nbrMonth.select('expresse')
      form.updateFieldAppearances();
      //form.flatten()
      // Create a new PDFDocument

      /////////////// page 2 ///////////////////////////////////////
      var url0 = `/pdf/vattenfall/contartVattenfallMerged-2.pdf`;
      const pageOne = await fetch(url0).then((res) => res.arrayBuffer());
      // Load a PDFDocument from each of the existing PDFs
      const pageOneDoc = await PDFDocument.load(pageOne);

      const form2 = pageOneDoc.getForm();
      const nomClientp2 = form2.getTextField("nomClientp2");
      nomClientp2.setText(data.nom + " " + data.prenom);
      nomClientp2.setFontSize(7);

      const fonctionp2 = form2.getTextField("fonctionp2");
      if (data.fonction == "") {
        fonctionp2.setText("gerant");
      } else {
        fonctionp2.setText(data.fonction);
      }
      fonctionp2.setFontSize(7);

      nomClientp2.enableReadOnly();
      fonctionp2.enableReadOnly();
      dateEffet.enableReadOnly();

      //nbrMonth.select('expresse')
      form2.updateFieldAppearances();
      /////////////// page 3 ///////////////////////////////////////
      var url0 = `/pdf/vattenfall/contartVattenfallMerged-3.pdf`;
      const pageTwo = await fetch(url0).then((res) => res.arrayBuffer());
      // Load a PDFDocument from each of the existing PDFs
      const pageTwoDoc = await PDFDocument.load(pageTwo);
      const form3 = pageTwoDoc.getForm();
      const fields = form3.getFields();
      fields.forEach((field) => {
        const type = field.constructor.name;
        const name = field.getName();
      });
      const dateAuj = form3.getTextField("dateAuj");
      dateAuj.setText(data.dateAjourdhui);
      dateAuj.setFontSize(7);
      const nomAuj = form3.getTextField("nameAuj");
      nomAuj.setText(data.nom + " " + data.prenom);
      nomAuj.setFontSize(7);
      const titreAuj = form3.getTextField("titreAuj");
      if (data.fonction == "") {
        titreAuj.setText("gerant");
      } else {
        titreAuj.setText(data.fonction);
      }
      titreAuj.setFontSize(7);

      dateAuj.enableReadOnly();
      nomAuj.enableReadOnly();
      titreAuj.enableReadOnly();
      //nbrMonth.select('expresse')
      form3.updateFieldAppearances();
      /////////////// page 4 ///////////////////////////////////////
      var url0 = `/pdf/vattenfall/contartVattenfallMerged-4.pdf`;
      const pageThree = await fetch(url0).then((res) => res.arrayBuffer());
      const pageThreeDoc = await PDFDocument.load(pageThree);
      const form4 = pageThreeDoc.getForm();

      const pdl4 = form4.getTextField("pdl4");
      pdl4.setText(data.pdl);
      pdl4.setFontSize(7);

      pdl4.enableReadOnly();
      //nbrMonth.select('expresse')
      form4.updateFieldAppearances();
      // Load a PDFDocument from each of the existing PDFs

      /////////////// page 5 ///////////////////////////////////////
      var url0 = `/pdf/vattenfall/contartVattenfallMerged-5.pdf`;
      const page4 = await fetch(url0).then((res) => res.arrayBuffer());
      // Load a PDFDocument from each of the existing PDFs
      const page4Doc = await PDFDocument.load(page4);

      /////////////// page 6 ///////////////////////////////////////
      var url0 = `/pdf/vattenfall/contartVattenfallMerged-6.pdf`;
      const page5 = await fetch(url0).then((res) => res.arrayBuffer());
      // Load a PDFDocument from each of the existing PDFs
      const page5Doc = await PDFDocument.load(page5);

      /////////////// page 7 ///////////////////////////////////////
      var url0 = `/pdf/vattenfall/contartVattenfallMerged-7.pdf`;
      const page6 = await fetch(url0).then((res) => res.arrayBuffer());
      // Load a PDFDocument from each of the existing PDFs
      const page6Doc = await PDFDocument.load(page6);

      /////////////// page 8 ///////////////////////////////////////
      var url0 = `/pdf/vattenfall/contartVattenfallMerged-8.pdf`;
      const page7 = await fetch(url0).then((res) => res.arrayBuffer());
      // Load a PDFDocument from each of the existing PDFs
      const page7Doc = await PDFDocument.load(page7);

      /////////////// page 8 ///////////////////////////////////////
      var url0 = `/pdf/vattenfall/contartVattenfallMerged-9.pdf`;
      const page8 = await fetch(url0).then((res) => res.arrayBuffer());
      // Load a PDFDocument from each of the existing PDFs
      const page8Doc = await PDFDocument.load(page8);

      let mergedPdf = await PDFDocument.create();
      mergedPdf = await this.addPagesToPdf(mergedPdf, pageZeroDoc);
      mergedPdf = await this.addPagesToPdf(mergedPdf, pageOneDoc);
      mergedPdf = await this.addPagesToPdf(mergedPdf, pageTwoDoc);
      mergedPdf = await this.addPagesToPdf(mergedPdf, pageThreeDoc);
      mergedPdf = await this.addPagesToPdf(mergedPdf, page4Doc);
      mergedPdf = await this.addPagesToPdf(mergedPdf, page5Doc);
      mergedPdf = await this.addPagesToPdf(mergedPdf, page6Doc);
      mergedPdf = await this.addPagesToPdf(mergedPdf, page7Doc);
      mergedPdf = await this.addPagesToPdf(mergedPdf, page8Doc);
      const pdfBytes = await mergedPdf.save();
      var generatedPdf = pdfBytes;
      return generatedPdf;
    },
  },
};
</script>
